#about {
  padding: 4rem 0;
}
.exper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.exper__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}

.exper > h2 {
  margin-bottom: 1rem;
}

.exper-in {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.about__right h2 {
  margin: 1rem 0;
}

.about__right p {
  margin-bottom: 0.8rem;
}

.about__right .btn {
  margin-top: 2.5rem;
}

.card.exper {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.exper__icon {
  background: var(--color-primary);
  padding: 0.6rem;
  border-radius: var(--border-radius-3);
  font-size: 1.5rem;
  color: var(--color-white);
  transition: var(--transition);
}

.exper:hover .exper__icon {
  background: var(--color-light);
  color: var(--color-primary);
}

.exper h4 {
  margin-bottom: 0.7rem;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1300px) {
  .exper__container {
    gap: 1.7rem;
  }
  .exper-in {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .about__container {
    grid-template-columns: 1fr;
  }

  .about__right h2 {
    text-align: center;
  }
  .exper__details h4 {
    text-align: center;
  }
  .exper__details i {
    text-align: center;
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 800px) {
  .exper__container {
    grid-template-columns: 1fr;
    gap: 1.7rem;
  }
  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__right .btn {
    margin-top: 2rem;
  }
  .about__right h5 {
    line-height: 1.5rem !important;
  }
  .exper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}
