footer {
    background: var(--color-primary);
    padding: 3rem;
    box-shadow: 0 -1rem 1.5rem hsla(var(--primary-hue), 68%, 42%, 20%);
    transition: var(--transition);
    height: max-content;
}

footer .nav__menu {
    display: flex;

    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 1024px) {
    footer{
        display: none;
    }
}

