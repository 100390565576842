.achievements__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 4rem;
}

.card.achievement {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.achievement__icon {
  background: var(--color-primary);
  padding: 0.6rem;
  border-radius: var(--border-radius-3);
  font-size: 1.5rem;
  color: var(--color-white);
  transition: var(--transition);
}

.achievement:hover .achievement__icon {
  background: var(--color-light);
  color: var(--color-primary);
}

.achievement h4 {
  margin-bottom: 0.7rem;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1224px) {
  .achievements__container {
    gap: 1.5rem;
  }
  .card.achievement {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .achievement__details h5 {
    text-align: center;
  }
  .achievement__rank {
    text-align: center;
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 800px) {
  .achievements__container {
    grid-template-columns: 1fr;
    gap: 1.7rem;
  }

  .card.achievement {
    gap: 1.2rem;
  }
}
